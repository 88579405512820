import {
	PUBLIC_SENTRY_DSN,
	PUBLIC_SENTRY_ENV,
	PUBLIC_API_URL
} from '$env/static/public'
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

const ignoreErrors = [
	// Block Adyen form error
	"undefined is not an object (evaluating 'this.touchendListener')"
]

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	environment: PUBLIC_SENTRY_ENV,
	tracesSampleRate: 1.0,
	ignoreErrors: ignoreErrors,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [
		replayIntegration({
			networkDetailAllowUrls: [
				`${PUBLIC_API_URL}/api/v1/dispenses`,
				`${PUBLIC_API_URL.replace(
					/[.*+?^${}()|[\]\\]/g,
					'\\$&'
				)}\/api\/v1\/sessions\/[A-Za-z0-9]+\/status`
			],
			networkRequestHeaders: ['X-Custom-Header'],
			networkResponseHeaders: ['X-Custom-Header']
		})
	]
})

setTimeout(() => {
	const svelteApp = document.querySelector('#app')
	if (!svelteApp?.hasChildNodes()) {
		Sentry.captureException('Failed to render Svelte App')
	}
}, 4000)

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
